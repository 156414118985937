import React, { useState } from 'react';
import './Blog.scss';

const blogPosts = [
    {
      id: 1,
      date: "February 6, 2025",
      title: "Introduction to Machine Learning",
      author: "Joshua Aragon",
      preview:
        "An in-depth guide to machine learning—from its core concepts and mathematical foundations to practical tools, deployment strategies, and tips for transitioning from software engineering to ML engineering.",
      tags: ["Machine Learning", "AI", "Career", "Engineering"],
      content: {
        body: [
          {
            type: "heading",
            content: "What is Machine Learning, and Why Does It Matter?"
          },
          {
            type: "paragraph",
            content:
              "Machine Learning (ML) is a subfield of artificial intelligence that enables computers to learn from data and improve their performance on tasks without being explicitly programmed step-by-step (What Is Machine Learning (ML)? | IBM). Instead of hard-coding rules, we provide examples (data) and let the algorithm infer patterns and make predictions or decisions. This approach is powerful because modern organizations deal with growing volumes of complex data, and ML can analyze this data to deliver faster, more accurate insights and predictions at scale (Machine Learning: What it is and why it matters | SAS). In practical terms, ML powers many real-world applications – from recommendation systems that suggest movies or products, to medical systems that help detect diseases. It matters because it allows software to tackle complex, data-driven problems (like speech recognition or fraud detection) that would be impractical to solve with manual programming of rules."
          },
          {
            type: "heading",
            content: "Traditional Programming vs. Machine Learning"
          },
          {
            type: "paragraph",
            content:
              "If you're coming from a traditional software engineering background, the ML paradigm can feel like a shift in mindset. In traditional programming, you (the developer) explicitly define the rules and logic. The program will only do what you hard-code it to do, and if requirements or data change, you must rewrite or update the rules. As one source puts it: in traditional programming, a programmer manually provides specific instructions to solve a problem, and if the problem or input changes, the code must be adjusted by hand (What is the difference between machine learning and traditional programming?). In machine learning, by contrast, you don’t hand-code the solution – instead, you feed data into a general algorithm and the computer learns the rules on its own. It’s akin to teaching by example: rather than coding a thousand rules to detect spam emails, you can train an ML model on a large dataset of labeled “spam” and “not spam” emails, and the model will learn the distinguishing patterns. Because the model has learned from data instead of fixed rules, it can adapt to new or changing inputs on its own. In short, traditional programming is like writing a detailed recipe, whereas ML is like training a chef who learns to cook by tasting and experimenting with many dishes. This adaptability and data-driven learning give ML its edge in dealing with complex or evolving problems."
          },
          {
            type: "heading",
            content:
              "Categories of ML: Supervised, Unsupervised, Reinforcement Learning"
          },
          {
            type: "paragraph",
            content:
              "Machine Learning isn’t a single approach – it encompasses a few major learning paradigms that differ in how the model learns from data:"
          },
          {
            type: "subheading",
            content: "Supervised Learning"
          },
          {
            type: "paragraph",
            content:
              "By far the most common category, supervised learning uses labeled examples to train a model. You provide the algorithm with input data and the desired output (label) for each example, and the algorithm learns a general mapping from inputs to outputs. Supervised learning is defined by its use of labeled datasets to train models to classify data or predict outcomes (What Is Machine Learning (ML)? | IBM). For example, given past data about houses (size, location, etc.) and their sale prices, a supervised learning algorithm can learn to predict house prices. Classification (predicting a category) and regression (predicting a numeric value) are key types of supervised tasks."
          },
          {
            type: "subheading",
            content: "Unsupervised Learning"
          },
          {
            type: "paragraph",
            content:
              "In unsupervised learning, the data has no labels. The algorithm must find structure in the input data on its own. This often means discovering hidden patterns or groupings. For instance, an unsupervised algorithm might cluster customers into distinct groups based on purchasing behavior, without being told what those groups are in advance. In other words, unsupervised learning algorithms analyze and cluster unlabeled datasets to find patterns or groupings without human guidance (What Is Machine Learning (ML)? | IBM). It’s useful for tasks like customer segmentation, anomaly detection, or data compression."
          },
          {
            type: "subheading",
            content: "Reinforcement Learning"
          },
          {
            type: "paragraph",
            content:
              "Reinforcement learning is inspired by behavioral psychology – an “agent” learns by interacting with an environment and receiving feedback in the form of rewards or penalties. There is no fixed training dataset; instead, the model learns through trial and error. Over many iterations, it strives to maximize cumulative reward, figuring out the best strategy (or policy) to achieve its goals. In essence, the algorithm isn’t told the correct actions, but it is reinforced for good outcomes (What Is Machine Learning (ML)? | IBM). This approach shines in scenarios like game-playing AI, robotics, or any sequential decision-making task."
          },
          {
            type: "heading",
            content: "Mathematical Foundations of ML"
          },
          {
            type: "paragraph",
            content:
              "Transitioning into ML engineering requires brushing up on certain mathematical concepts. Don’t worry – you don’t need a PhD in math to get started, but key areas of linear algebra, probability/statistics, and optimization form the backbone of most ML algorithms. In fact, it’s often said that linear algebra, probability, and calculus are the languages in which machine learning is written (Scalars, Vectors, Matrices and Tensors - Linear Algebra for Deep Learning (Part 1) | QuantStart)."
          },
          {
            type: "subheading",
            content: "Linear Algebra"
          },
          {
            type: "paragraph",
            content:
              "Vectors, Matrices, Tensors – Machine learning models are typically expressed in mathematical structures like vectors and matrices. At its core, a vector is just an ordered array of numbers (like a list of features), and a matrix is a two-dimensional grid of numbers (think of a table or 2D array). A tensor is a generalization of a matrix to higher dimensions – essentially an n-dimensional array (for example, an image with width, height, and color channels can be represented as a 3D tensor). Understanding linear algebra helps you reason about transformations on data and is especially crucial for deep learning."
          },
          {
            type: "subheading",
            content: "Probability and Statistics"
          },
          {
            type: "paragraph",
            content:
              "Probability theory provides a framework for reasoning under uncertainty – it lets us model how likely events are and how to update our beliefs in light of new data. In ML, we use probability distributions to model data and algorithms. A solid grasp of statistics helps in evaluating models and making predictions with confidence. Concepts like Bayesian inference play a key role in updating model beliefs based on new evidence."
          },
          {
            type: "subheading",
            content: "Optimization: Gradient Descent and Variants"
          },
          {
            type: "paragraph",
            content:
              "Once we have a model and a way to measure its error (loss function), we need to optimize the model parameters to minimize that error. Gradient descent is an iterative algorithm that adjusts parameters in the direction that most reduces error. Variants like Stochastic Gradient Descent, Momentum, RMSProp, and Adam tweak the basic approach to improve speed and convergence. Optimization is essential for training models efficiently in high-dimensional spaces."
          },
          {
            type: "heading",
            content: "Programming and Tools for ML"
          },
          {
            type: "paragraph",
            content:
              "From a tooling perspective, machine learning has a very rich ecosystem. As a software engineer, you’ll find many familiar elements as well as new frameworks tailored to ML."
          },
          {
            type: "subheading",
            content: "Python for ML: Essential Libraries"
          },
          {
            type: "list",
            items: [
              "NumPy: Fundamental package for numerical computing.",
              "Pandas: Data manipulation and analysis.",
              "Scikit-learn: User-friendly machine learning library for classical ML algorithms."
            ]
          },
          {
            type: "subheading",
            content: "Deep Learning Frameworks: TensorFlow and PyTorch"
          },
          {
            type: "paragraph",
            content:
              "TensorFlow and PyTorch are the dominant frameworks for deep learning. TensorFlow, often used with Keras, is production-ready and scalable, while PyTorch is favored for its dynamic computation graph and intuitive design, especially in research."
          },
          {
            type: "subheading",
            content: "Cloud-Based ML Tools"
          },
          {
            type: "paragraph",
            content:
              "Cloud platforms like Google Cloud Vertex AI, AWS SageMaker, and Azure ML offer managed services for building, training, and deploying ML models. These tools simplify scaling and integration with existing cloud infrastructure."
          },
          {
            type: "heading",
            content: "Building ML Models: From Theory to Implementation"
          },
          {
            type: "subheading",
            content: "Data Collection, Preprocessing, and Feature Engineering"
          },
          {
            type: "paragraph",
            content:
              "The quality of your ML model depends on the data you feed it. Data collection involves gathering relevant data, while preprocessing cleans and structures it. Feature engineering transforms raw data into meaningful features that enhance model performance."
          },
          {
            type: "subheading",
            content: "Choosing the Right Model"
          },
          {
            type: "paragraph",
            content:
              "With prepared data, selecting the appropriate algorithm is key. Options range from linear regression and decision trees to neural networks. The choice depends on factors like interpretability, dataset size, and problem complexity."
          },
          {
            type: "subheading",
            content: "Training, Validating, and Testing Models"
          },
          {
            type: "paragraph",
            content:
              "Training involves fitting your model on the training dataset, while validation and testing assess its performance on unseen data. Techniques like cross-validation help ensure that the model generalizes well."
          },
          {
            type: "subheading",
            content: "Overfitting and Regularization Techniques"
          },
          {
            type: "paragraph",
            content:
              "Overfitting occurs when a model learns the training data too well – including noise – resulting in poor generalization. Regularization techniques such as L1/L2 penalties, dropout, and early stopping help mitigate overfitting by simplifying the model."
          },
          {
            type: "heading",
            content: "Deploying and Scaling ML Models"
          },
          {
            type: "subheading",
            content: "Model Serving"
          },
          {
            type: "paragraph",
            content:
              "Model serving involves deploying your trained model so it can make real-time or batch predictions. This can be done via REST APIs for real-time inference, batch processing jobs, or streaming inference systems for continuous data."
          },
          {
            type: "subheading",
            content: "Model Optimization for Production"
          },
          {
            type: "paragraph",
            content:
              "Techniques like quantization and pruning reduce model size and improve inference speed without significantly sacrificing accuracy, which is crucial for production environments with resource constraints."
          },
          {
            type: "subheading",
            content: "MLOps: Continuous Training and Monitoring"
          },
          {
            type: "paragraph",
            content:
              "MLOps applies DevOps principles to ML workflows. It encompasses continuous training, versioning, and monitoring of models to ensure they remain effective over time and can be updated seamlessly as new data arrives."
          },
          {
            type: "heading",
            content: "Practical Case Studies and Examples"
          },
          {
            type: "subheading",
            content: "Fraud Detection in Finance"
          },
          {
            type: "paragraph",
            content:
              "ML models are used to detect fraudulent transactions by analyzing historical data for unusual patterns. This enables real-time fraud prevention in banking and financial services."
          },
          {
            type: "subheading",
            content: "Predictive Maintenance in Manufacturing"
          },
          {
            type: "paragraph",
            content:
              "By analyzing sensor data from equipment, ML models can predict failures before they occur, allowing for proactive maintenance and reducing downtime."
          },
          {
            type: "subheading",
            content: "Natural Language Processing in Customer Support"
          },
          {
            type: "paragraph",
            content:
              "NLP models power chatbots and sentiment analysis systems that improve customer service by automating responses and categorizing support tickets."
          },
          {
            type: "subheading",
            content: "Image Recognition in Healthcare"
          },
          {
            type: "paragraph",
            content:
              "Deep learning models analyze medical images to detect diseases such as tumors or fractures, assisting doctors in diagnostics and treatment planning."
          },
          {
            type: "heading",
            content: "Transitioning from Software Engineer to ML Engineer"
          },
          {
            type: "subheading",
            content: "Developing a Data Science Mindset"
          },
          {
            type: "paragraph",
            content:
              "Transitioning to ML engineering involves supplementing your software skills with knowledge of data analysis, statistics, and machine learning algorithms. Embrace a mindset of experimentation and continuous learning."
          },
          {
            type: "subheading",
            content: "Online Courses and Certifications"
          },
          {
            type: "paragraph",
            content:
              "High-quality online courses and certifications, such as Andrew Ng’s Machine Learning course or deep learning specializations, provide structured learning paths to build your ML expertise."
          },
          {
            type: "subheading",
            content: "Building Projects and Open-Source Contributions"
          },
          {
            type: "paragraph",
            content:
              "Hands-on projects—whether personal or collaborative—are essential for learning ML in practice. Building a portfolio of projects and contributing to open-source initiatives can showcase your skills to potential employers."
          },
          {
            type: "subheading",
            content: "Interview Preparation"
          },
          {
            type: "paragraph",
            content:
              "Prepare for ML engineer roles by mastering both coding challenges and ML concepts. Be ready to discuss your projects, the algorithms you used, and how you addressed issues like overfitting and model deployment."
          },
          {
            type: "subheading",
            content: "Continuous Learning and Community Engagement"
          },
          {
            type: "paragraph",
            content:
              "The field of ML is rapidly evolving. Stay current by engaging with the community through blogs, forums, conferences, and meetups. Continuous learning is key to long-term success in ML engineering."
          }
        ]
      }
    }
  ];
  

const Blog = () => {
  const [selectedPost, setSelectedPost] = useState(null);

  const renderContent = (content) => {
    return content.body.map((section, index) => {
      switch (section.type) {
        case 'paragraph':
          return <p key={index}>{section.content}</p>;
        case 'heading':
          return <h2 key={index}>{section.content}</h2>;
        case 'subheading':
          return <h3 key={index}>{section.content}</h3>;
        case 'list':
          return (
            <ul key={index}>
              {section.items.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          );
        case 'blockquote':
          return (
            <blockquote key={index}>
              <p>{section.content}</p>
            </blockquote>
          );
        case 'code':
          return (
            <pre key={index}>
              <code>{section.content}</code>
            </pre>
          );
        default:
          return null;
      }
    });
  };

  return (
    <div className="blog-container">
      {selectedPost ? (
        <>
          <button 
            className="back-button" 
            onClick={() => setSelectedPost(null)}
          >
            ← Back to posts
          </button>
          <article className="blog-content">
            <header className="blog-header">
              <span className="blog-date">{selectedPost.date}</span>
              <h1>{selectedPost.title}</h1>
              <div className="blog-tags">
                {selectedPost.tags.map((tag, index) => (
                  <span key={index} className="tag">{tag}</span>
                ))}
              </div>
            </header>
            <section className="blog-body">
              <p className="lead">{selectedPost.content.lead}</p>
              {renderContent(selectedPost.content)}
            </section>
          </article>
        </>
      ) : (
        <div className="blog-grid">
          {blogPosts.map(post => (
            <div 
              key={post.id} 
              className="blog-preview"
              onClick={() => setSelectedPost(post)}
            >
              <span className="blog-date">{post.date}</span>
              <h2>{post.title}</h2>
              <p>{post.preview}</p>
              <div className="blog-tags">
                {post.tags.map((tag, index) => (
                  <span key={index} className="tag">{tag}</span>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Blog;